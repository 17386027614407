import { addCall } from "reducers/slices/callReducer";
import { addOtherUserCall, claimSlot, addUnansweredCall as addUnansweredCallRedux, removeUnansweredCall, removeOtherUserCall, setActiveCall, setSelectedCall, addCallTimeout, clearCallTimeout } from "reducers/slices/callSlotReducer";
import { CALL_TIMEOUT, CallState, CallStateState, CallType, TimestampAction, UnboundCallType, WarmTransferredTo, interactionsToTimestamps, operatorCanPickUpCall, parseFlagNotes } from "utils/calls";
import { useContext } from "react";
import { SocketContext } from "context/socket-context";
import { TwilioContext, TwilioInterface } from "context/twilio-context";
import { SnackbarSeverity, setIsRinging, setSnackbar } from "reducers/slices/UIReducer";
import { DtmfContext } from "context/dtmf-context";
import { Dtmf } from "play-dtmf";
import { logger, ViewRoute } from "utils/utils";
import { useAppDispatch } from "reducers/hooks";
import { canOutboundCall, getActiveCall, getCallByConferenceName, getCallerDelay, hasProcessingCall, isSocketOffline, removeCallThunk, unclaimCallSlotThunk, updateCallThunk } from "reducers/thunks/callThunks";
import { getActiveAccount } from "reducers/thunks/accountThunk";
import { getLoggedInUser } from "reducers/thunks/userThunk";
import { AppThunkDispatch } from "reducers/types";
import { useNavigate } from "react-router-dom";

const useCallFunctions  = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  const socket = useContext(SocketContext)?.socket
  const twilioContext = useContext(TwilioContext) as TwilioInterface
  const { device } = twilioContext
  const dtmfPlayer = useContext(DtmfContext)?.dtmfPlayer

  const stopRinging = () => {
    dispatch(setIsRinging(false))
  }

  const addUnansweredCall = (conferenceName: string, callData?: any, fromEvents?: boolean, requeued?: boolean) => {
    const {
      accountId,
      callRecordId,
      name,
      emailSentOnCalls,
      hipaaCompliant,
      commonMistakes,
      spanishCalls,
      spanishAccount,
      spanishFlag,
      tlc,
      tlcPopup,
      abbyStage2,
      abbyTeamIds,
      block,
      evoLevel,
      isTest,
      requeueToOperatorId,
      callerNumber,
      callerCallSid,
      notes,
      flagNotes,
      interactions,
      callReceivedTime,
    } = callData || {};

    const timestamps = interactionsToTimestamps(interactions, callReceivedTime)

    const call: CallState = {
      requeued,
      accountId,
      callRecordId,
      name,
      conferenceName,
      state: CallStateState.UNANSWERED,
      timestamps: timestamps?.length ? timestamps : [{
        action: requeued ? TimestampAction.REQUEUE : TimestampAction.RECEIVED,
        timestamp: requeued ? Date.now() : callReceivedTime,
      }],
      isTest,
      createdAt: Date.now(),
      type: CallType.INBOUND,
      conferenceJoinedBy: '',
      hipaaCompliant,
      commonMistakes,
      tlc,
      tlcPopup,
      spanishCalls,
      spanishAccount,
      spanishFlag,
      emailSentOnCalls,
      abbyStage2,

      abbyTeamIds,
      block,
      evoLevel,
      requeueToOperatorId,

      callerNumber,
      callerCallSid,
      notes,
      flagNotes: parseFlagNotes(flagNotes),

      transferEnabled: false,
    }

    // CCAA-1439 Calls from events are trusted and should override isRemoved flag
    if (fromEvents) {
      updateCall(conferenceName, call, true)
    } else {
      const existingCall = thunkDispatch(getCallByConferenceName(conferenceName, false))
      // There is an existing call and it's not a requeue, so let's skip this
      if (existingCall) {
        return
      }

      dispatch(addCall(call))
    }

    if (requeued) {
      dispatch(removeOtherUserCall(conferenceName))
    }

    const user = thunkDispatch(getLoggedInUser())
    const operator = {
      id: user?.userId || '',
      spanishCalls: user?.details?.spanishCalls === 'true',
      evoLevels: user?.details?.evoLevels || '',
      userBlock: user?.details?.userBlock || '',
      teamId: user?.details?.teamId || '',
      teamName: user?.details?.teamName || '',
      teamBlock: user?.details?.teamBlock || '',
    }
    if (operatorCanPickUpCall(operator, call)) {
      dispatch(claimSlot({ conferenceName }))
    }
    dispatch(addUnansweredCallRedux(conferenceName))
  }

  const addOutboundCall = (conferenceName: string, callData?: any) => {
    const {
      accountId,
      name,
      isTest,
      operatorId,
      callRecordId,
      callerNumber,
    } = callData || {};

    const call: CallState = {
      accountId,
      callRecordId,
      name: name || 'Outbound Call',
      conferenceName,
      callerNumber,
      state: CallStateState.UNANSWERED,
      timestamps: [{
        action: TimestampAction.RINGING,
        timestamp: Date.now(),
      }],
      isTest,
      createdAt: Date.now(),
      type: CallType.OUTBOUND,
    }

    const user = thunkDispatch(getLoggedInUser())
    if (operatorId === user?.userId) {
      updateCall(conferenceName, call)
    } else {
      updateCall(conferenceName, call, true)
      dispatch(addOtherUserCall({ userId: operatorId, conferenceName }))
    }
  }

  const updateCall = (conferenceName: string, update: Partial<CallState>, addIfMissing?: boolean) => {
    thunkDispatch(updateCallThunk({ conferenceName, update, addIfMissing }))
  }
  
  const removeCall = (conferenceName: string, skipRequeued = false) => {
    const call = thunkDispatch(getCallByConferenceName(conferenceName))
    if (skipRequeued) {
      if (call?.requeued) {
        return
      }
    }

    logger('Unclaiming call slot as the call is removed', { conferenceName })
    thunkDispatch(unclaimCallSlotThunk(conferenceName))
    dispatch(removeUnansweredCall(conferenceName))
    dispatch(removeOtherUserCall(conferenceName))
    thunkDispatch(removeCallThunk(conferenceName))
  }
  
  const callJoined = (conferenceName: string, conferenceJoinedBy: string) => {
    updateCall(conferenceName, { conferenceJoinedBy })

    dispatch(removeUnansweredCall(conferenceName))
    dispatch(addOtherUserCall({ userId: conferenceJoinedBy, conferenceName }))

    const user = thunkDispatch(getLoggedInUser())
    if (conferenceJoinedBy !== user?.userId) {
      logger('Unclaiming call slot as the call is joined by another user', { conferenceName, conferenceJoinedBy, userId: user?.userId })
      thunkDispatch(unclaimCallSlotThunk(conferenceName))
    }
  }

  const callAnswered = (conferenceName: string) => {
    const user = thunkDispatch(getLoggedInUser())

    updateCall(conferenceName, {
      conferenceJoinedBy: user?.userId,
      state: CallStateState.ANSWERED,
      timestamps: [{
        action: TimestampAction.ANSWER,
        timestamp: Date.now(),
      }],
      requeueToOperatorId: undefined,
      joinedAt: Date.now(),
    })
  }

  const callTaken = (conferenceName: string, takenById: string, takenBy: string) => {
    const call = thunkDispatch(getCallByConferenceName(conferenceName))
    const user = thunkDispatch(getLoggedInUser())
    updateCall(conferenceName, { conferenceJoinedBy: takenById })

    if (call?.conferenceJoinedBy === user?.userId) {
      if (takenById !== user?.userId) {
        logger('Unclaiming call slot as the call is taken by another user', { conferenceName, takenById, userId: user?.userId })
        dispatch(unclaimCallSlotThunk(conferenceName))
        dispatch(addOtherUserCall({ userId: takenById, conferenceName }))
        dispatch(setSnackbar({ message: `Your call was taken by ${takenBy}`, severity: SnackbarSeverity.WARNING }))
      }
    } else {
      dispatch(removeOtherUserCall(conferenceName))
      dispatch(addOtherUserCall({ userId: takenById, conferenceName }))
    }
  }

  // CCAA-963 Handle parts of callJoined as well,
  // handling edge case where login user receives callStarted but not callJoined
  const callStarted = (conferenceName: string, callSid?: string) => {
    // Parts of callJoined
    dispatch(removeUnansweredCall(conferenceName))

    const call = thunkDispatch(getCallByConferenceName(conferenceName))
    const user = thunkDispatch(getLoggedInUser())
    if (call?.conferenceJoinedBy !== user?.userId) {
      logger('Unclaiming call slot as the call is joined by another user', { conferenceName, conferenceJoinedBy: call?.conferenceJoinedBy, userId: user?.userId })
      thunkDispatch(unclaimCallSlotThunk(conferenceName))
      if (call?.conferenceJoinedBy) {
        dispatch(addOtherUserCall({ userId: call.conferenceJoinedBy, conferenceName }))
      }
    }
    // end parts of callJoined

    const update: Partial<CallState> = {
      state: CallStateState.ANSWERED,
      timestamps: [{
        action: TimestampAction.ANSWER,
        timestamp: Date.now(),
      }],
      transferEnabled: true,
    }
    if (callSid) {
      update.callerCallSid = callSid
    }

    updateCall(conferenceName, update)
  }

  const callReconnected = (conferenceName: string) => {
    updateCall(conferenceName, {
      transferEnabled: true,
      isProcessing: false,
      joinedAt: Date.now(),
    })
  }

  // Not in warm transfer or waiting for BE
  const canSwitchCall = (activeCall: CallState) => {
    if (activeCall.warmTransferredToNum) {
      dispatch(setSnackbar({
        message: 'Please finish the current warm transfer before switching to another call.',
        severity: SnackbarSeverity.WARNING
      }))
      return false
    }

    if (activeCall.isProcessing) {
      dispatch(setSnackbar({
        message: 'Another call is still processing, please wait for a moment.',
        severity: SnackbarSeverity.WARNING
      }))
      return false
    }

    if (activeCall.type === CallType.UNBOUND) {
      dispatch(setSnackbar({
        message: 'Please finish the current call before switching to another call.',
        severity: SnackbarSeverity.WARNING
      }))
      return false
    }

    // Is dialing outbound number
    if (activeCall.type === CallType.OUTBOUND && activeCall.state === CallStateState.UNANSWERED) {
      dispatch(setSnackbar({
        message: 'You cannot switch to another call, while you are initiating an outgoing call.',
        severity: SnackbarSeverity.WARNING
      }))
      return false
    }

    return true
  }

  const startCallTimeout = (conferenceName: string, cc_reconnect = '') => {
    const callTimeout = setTimeout(() => {
      const conference = thunkDispatch(getCallByConferenceName(conferenceName))
      if (!conference) return

      // Call is still stuck at processing
      if ([CallStateState.UNANSWERED, CallStateState.ONHOLD].includes(conference.state) && conference.isProcessing) {
        updateCall(conferenceName, { isProcessing: false })

        const errorMessage = 'CC001 - Call timed out'
        onCallConnectError(new Error(errorMessage))
        notifyCallFailed(conferenceName, cc_reconnect, errorMessage)
      }
    }, CALL_TIMEOUT)

    dispatch(addCallTimeout(callTimeout))
    return callTimeout
  }

  const joinCall = async (conferenceName: string, isReconnect = false, isTakeCall = false) => {
    if (thunkDispatch(hasProcessingCall())) return
    if (!isReconnect && thunkDispatch(isSocketOffline())) return

    if (isReconnect) {
      // @ts-ignore _activeCall is private, but it's way better than manually maintaining the active call on our app
      const twilioCall = device?._activeCall

      // There is an active call, skip reconnecting
      if (twilioCall) {
        return
      }
    }

    const activeCall = thunkDispatch(getActiveCall())
    updateCall(conferenceName, { isProcessing: true })

    if (activeCall && !isReconnect) {
      if (canSwitchCall(activeCall)) {
        // On reconnect active call can be immediately available
        // but we still want to join it, just no need to hold it first.
        if (activeCall.conferenceName !== conferenceName) {
          await holdCall(activeCall.conferenceName, true)
        }
      } else {
        logger('Unable to switch call', { conferenceName, activeCall })
        updateCall(conferenceName, { isProcessing: false })
        return
      }
    }

    const conference = thunkDispatch(getCallByConferenceName(conferenceName))
    dispatch(setActiveCall(conference))

    stopRinging()

    if (isTakeCall) {
      dispatch(setSelectedCall(conference))
    }

    try {
      const params: Record<string, string> = {
        conferenceName,
        joinAs: 'receptionist',
        callerDelay: thunkDispatch(getCallerDelay()),
      }
      // BE does not handle unanswered for take call, so just do regular answer
      if (isTakeCall && conference?.state !== CallStateState.UNANSWERED) params.takeCall = 'true'
      if (isReconnect) params.cc_reconnect = 'true'

      logger('Joining call', params)

      const callTimeout = startCallTimeout(conferenceName, params.cc_reconnect)
      const call = await device?.connect({ params })

      logger('Connecting to call', params)

      if (call) {
        call.on('accept', () => {
          dispatch(clearCallTimeout(callTimeout))
          /**
           * CCAA-747 There is a race condition in which the operator answer the call
           * right as the call gets rebroadcasted. In this case, the call will still connect
           * but it will ring again as it gets rebroadcasted. Hence we do a duplicate stopRinging here.
           */
          stopRinging()

          logger('Joined call', params)
          if (isReconnect) {
            callReconnected(conferenceName)
          } else {
            dispatch(setActiveCall(conference))

            if (isTakeCall) {
              navigate(ViewRoute.RECEPTIONIST_VIEW)
              dispatch(claimSlot({ conferenceName, autoSelect: true }))
              dispatch(removeOtherUserCall(conferenceName))

              if (conference?.state === CallStateState.ONHOLD || conference?.state === CallStateState.UNANSWERED) {
                callAnswered(conferenceName)
              } else {
                const user = thunkDispatch(getLoggedInUser())
                updateCall(conferenceName, {
                  conferenceJoinedBy: user?.userId,
                  isProcessing: false,
                  requeueToOperatorId: undefined,
                  joinedAt: Date.now(),
                })
              }
            } else {
              callAnswered(conferenceName)
            }
          }

          notifyCallConnected(conferenceName, call.parameters.CallSid)
        })

        call.on('error', async error => {
          dispatch(clearCallTimeout(callTimeout))
          onCallConnectError(error)
          updateCall(conferenceName, { isProcessing: false })
          notifyCallFailed(conferenceName, params.cc_reconnect, error.message)
        })

        call.on('disconnect', async () => {
        })
      }
    } catch (err: any) {
      onCallConnectError(err)
      notifyCallFailed(conferenceName, isReconnect ? 'true' : '', err.message)
      updateCall(conferenceName, { isProcessing: false })
    }
  }

  const listenToCall = async (conferenceName: string) => {
    logger('Joining call as manager', conferenceName);
    if (thunkDispatch(hasProcessingCall())) return

    try {
      await device?.connect({
        params: {
          conferenceName,
          joinAs: 'manager'
        }
      });
    } catch (err) {
      onCallConnectError(err)
    }
  }

  const outboundCall = async (targetNumber: string, dialAs = false) => {
    if (thunkDispatch(hasProcessingCall())) return
    if (thunkDispatch(isSocketOffline())) return

    if (targetNumber.startsWith('VM')) {
      return unboundCall(targetNumber, UnboundCallType.VMBOX_MANAGEMENT)
    }
    if (targetNumber.startsWith('PT')) {
      return unboundCall(targetNumber, UnboundCallType.PT_MANAGEMENT)
    }

    const canPerformCall = thunkDispatch(canOutboundCall())
    if (!canPerformCall) return

    stopRinging()

    if (targetNumber.includes('*99')) {
      return unboundCall(targetNumber, UnboundCallType.VOICEMAIL, dialAs)
    }

    const account = thunkDispatch(getActiveAccount())
    const user = thunkDispatch(getLoggedInUser())
    try {
      const conferenceName = (user?.userId || '') + Date.now()
      const params: Record<string, string> = {
        outboundCall: targetNumber,
        conferenceName,
      }
      if (dialAs) params.dialAs = 'true'
      if (account?.id) params.accountId = account.id

      logger('Starting an outbound call', params)

      const callState: CallState = {
        accountId: account?.id,
        name: account?.name || 'Outbound Call',
        callerNumber: targetNumber,
        conferenceName,
        state: CallStateState.UNANSWERED,
        timestamps: [{
          action: TimestampAction.RINGING,
          timestamp: Date.now(),
        }],
        createdAt: Date.now(),
        type: CallType.OUTBOUND,
        conferenceJoinedBy: user?.userId,
        isProcessing: true,
      }

      dispatch(claimSlot({ conferenceName, isOutbound: true }))
      dispatch(addCall(callState))
      dispatch(setActiveCall(callState))
      dispatch(setSelectedCall(callState))

      const callTimeout = startCallTimeout(conferenceName)
      const call = await device?.connect({ params });

      if (call) {
        call.on('accept', () => {
          dispatch(clearCallTimeout(callTimeout))
          stopRinging()
          notifyCallConnected(conferenceName, call.parameters.CallSid)
        })

        call.on('error', async error => {
          dispatch(clearCallTimeout(callTimeout))
          onCallConnectError(error)
          updateCall(conferenceName, { isProcessing: false })
        })

        call.on('disconnect', async () => {
        })
      }
    } catch (err) {
      onCallConnectError(err)
    }
  }

  const unboundCall = async (targetNumber: string, type: UnboundCallType, dialAs = false) => {
    stopRinging()
    const account = thunkDispatch(getActiveAccount())
    const user = thunkDispatch(getLoggedInUser())

    try {
      const conferenceName = (user?.userId || '') + Date.now()
      let params: Record<string, string> = {
        conferenceName,
      }
      let name = targetNumber

      switch (type) {
        case UnboundCallType.VOICEMAIL:
          params = {
            vmBoxName: targetNumber.replace('*99', ''),
            userExt: 'x' + user?.details?.extension || '',
          }
          if (dialAs) params.dialAs = 'true'
          if (account?.id) params.accountId = account.id

          name = account?.name || 'Outbound Call'
          break;

        case UnboundCallType.PT_MANAGEMENT:
          params = {
            phoneTreeMngt: targetNumber.replace('PT', ''),
          }
          name = 'Phone Tree Management'
          break;

        case UnboundCallType.VMBOX_MANAGEMENT:
          params = {
            vmBoxMngt: targetNumber.replace('VM', ''),
          }
          name = 'VM Box Management'
          break;
      
        default:
          break;
      }

      logger('Starting an unbound call', params)

      const callState: CallState = {
        accountId: account?.id,
        name,
        callerNumber: targetNumber,
        conferenceName,
        state: CallStateState.UNANSWERED,
        timestamps: [{
          action: TimestampAction.RINGING,
          timestamp: Date.now(),
        }],
        createdAt: Date.now(),
        type: CallType.UNBOUND,
        conferenceJoinedBy: user?.userId,
        isProcessing: true,
      }

      dispatch(claimSlot({ conferenceName, isOutbound: true }))
      dispatch(addCall(callState))
      dispatch(setActiveCall(callState))
      dispatch(setSelectedCall(callState))

      const callTimeout = startCallTimeout(conferenceName)
      const call = await device?.connect({ params });

      if (call) {
        call.on('error', async error => {
          dispatch(clearCallTimeout(callTimeout))
          onCallConnectError(error)
          updateCall(conferenceName, { isProcessing: false })
        })

        call.on('accept', async () => {
          dispatch(clearCallTimeout(callTimeout))
          logger('Unbound call connected', { targetNumber, type })
          callAnswered(conferenceName)
        })

        call.on('disconnect', async () => {
          logger('Unbound call disconnected', { targetNumber, type })
          removeCall(conferenceName)
        })
      }
    } catch (err) {
      onCallConnectError(err)
    }
  }

  // Perform warm transfer on active call, or outbound call if no active call
  const outboundOrTransfer = (targetNumber: string, targetId?: string, clientName?: string) => {
    if (thunkDispatch(hasProcessingCall())) return
    const activeCall = thunkDispatch(getActiveCall())

    if (activeCall && targetNumber.includes('?')) {
      if (activeCall.state !== CallStateState.ANSWERED) {
        return tryDialExtension(activeCall, targetNumber)
      }

      if (activeCall.type === CallType.OUTBOUND && activeCall.callerNumber === targetNumber) {
        return tryDialExtension(activeCall, targetNumber)
      }
    }

    if (activeCall) {
      warmTransfer(activeCall.conferenceName, targetNumber, targetId, clientName)
    } else {
      outboundCall(targetNumber)
    }
  }

  // Check if the number is a valid extension, and if so dial it
  // Return true if it dials an extension, false otherwise
  const tryDialExtension = (call: CallState, number: string) => {
    if (call.type === CallType.OUTBOUND && call.callerNumber !== number && call.warmTransferredToNum !== number) return false
    if (call.type === CallType.INBOUND && call.warmTransferredToNum !== number) return false

    const extensionsDialed = call.extensionsDialed || []
    const splitNum = number.split('?').map(e => e.trim())
    splitNum.splice(0, 1)
    let undialedExtensions = splitNum.filter(e => !extensionsDialed.includes(e))

    // All extensions has been dialed, restart from 0
    if (!undialedExtensions.length && splitNum.length) {
      undialedExtensions = splitNum
    }

    if (undialedExtensions.length) {
      const extensionToDial = undialedExtensions[0]
      dialExtension(call, extensionToDial)
    }
  }

  const dialExtension = (call: CallState, extension: string) => {
    const extensionsDialed = call.extensionsDialed || []
    logger('Dialing extension: ', { conferenceName: call.conferenceName, warmTransferNum: call.warmTransferredToNum, extension });

    sendDigits(extension as Dtmf)
    updateCall(call.conferenceName, {
      isProcessing: call.isProcessing,
      extensionsDialed: extensionsDialed.concat(extension),
    })
  }

  const emitLeaveConference = (conferenceName: string, leaveType: string, requeueToOperatorId?: string) => {
    const payload = {
      conferenceName,
      leaveType,
      requeueToOperatorId
    };

    socket?.emit("LEAVE_CONFERENCE_OPERATOR", { payload: payload }, function (response: any) {
      logger('LEAVE_CONFERENCE_OPERATOR', { payload, response });
    });
  }

  const holdCall = async (conferenceName: string, skipProcessingCheck = false) => {
    if (!skipProcessingCheck && thunkDispatch(hasProcessingCall())) return
    updateCall(conferenceName, { isProcessing: true })
    const payload = {
      conferenceName,
      leaveType: 'putConferenceOnHold',
    };

    socket?.emit("LEAVE_CONFERENCE_OPERATOR", { payload: payload }, function (response: any) {
      logger('LEAVE_CONFERENCE_OPERATOR', { payload, response });
    });

    await device?.disconnectAll()

    updateCall(conferenceName, {
      state: CallStateState.ONHOLD,
      timestamps: [{
        action: TimestampAction.HOLD,
        timestamp: Date.now(),
      }],
      transferEnabled: false,
    })
  }

  const leaveCall = async (conferenceName: string, isVoicemail = false) => {
    if (thunkDispatch(hasProcessingCall())) return
    updateCall(conferenceName, { isProcessing: true })
    if (!isVoicemail) {
      emitLeaveConference(conferenceName, 'finishConference')
    }
    await device?.disconnectAll()
    removeCall(conferenceName)
  }

  const requeueCall = async (conferenceName: string, requeueToOperatorId?: string) => {
    if (thunkDispatch(hasProcessingCall())) return
    updateCall(conferenceName, { isProcessing: true })
    emitLeaveConference(conferenceName, 'requeueConference', requeueToOperatorId)
    device?.disconnectAll()

    removeCall(conferenceName)
  }

  const toggleWarmTransfer = (conferenceName: string, target: WarmTransferredTo) => {
    if (thunkDispatch(hasProcessingCall())) return
    const call = thunkDispatch(getCallByConferenceName(conferenceName))
    if (!call) return

    updateCall(conferenceName, { isProcessing: true })

    const payload = {
      conferenceName,
      action: 'toggle',
      toPhoneNumber: call.warmTransferredToNum,
      putOnHold: call.warmTransferredTo
    };

    socket?.emit("WARM_TRANSFER", { payload }, function (response: any) {
      logger('WARM_TRANSFER', { payload, response });
      updateCall(conferenceName, {
        warmTransferredTo: target,
        state: target === WarmTransferredTo.CALLER ? CallStateState.WITH_CALLER : CallStateState.WITH_CLIENT,
        timestamps: [{
          action: target === WarmTransferredTo.CALLER ? TimestampAction.TRANSFER_TO_CALLER : TimestampAction.TRANSFER_TO_CLIENT,
          timestamp: Date.now(),
        }]
      })
    });
  }

  const coldTransfer = (conferenceName: string, phoneNumTo: string, clientName?: string) => {
    if (thunkDispatch(hasProcessingCall())) return
    if (!phoneNumTo) {
      alert("To phone # is required for cold/warm transfer");
      return;
    }

    // Special VM box extension case
    if (phoneNumTo.includes('*99')) {
      return transferToVm(phoneNumTo.replace('*99', ''))
    }

    updateCall(conferenceName, { isProcessing: true })

    const payload = {
      conferenceName,
      leaveType: 'transferConference',
      transferType: 'coldTransfer',
      toPhoneNumber: phoneNumTo,
      clientName,
    };

    socket?.emit("LEAVE_CONFERENCE_OPERATOR", { payload: payload }, function (response: any) {
      logger('LEAVE_CONFERENCE_OPERATOR', { payload, response });
    });
  }

  const warmTransfer = (conferenceName: string, phoneNumTo: string, phoneId?: string, clientName?: string) => {
    if (thunkDispatch(hasProcessingCall())) return
    if (!phoneNumTo) {
      dispatch(setSnackbar({
        message: 'To phone # is required for cold/warm transfer',
        severity: SnackbarSeverity.ERROR
      }))
      return
    }

    if (phoneNumTo.includes('*99')) {
      dispatch(setSnackbar({
        message: 'Warm transfer to a VM Box (*99) is not supported.',
        severity: SnackbarSeverity.ERROR
      }))
      return
    }
    updateCall(conferenceName, { isProcessing: true, transfersDialed: [phoneId || phoneNumTo] })

    const payload = {
      conferenceName,
      action: 'initWarmTransfer',
      toPhoneNumber: phoneNumTo,
      clientName,
    };

    socket?.emit("WARM_TRANSFER", { payload }, function (response: any) {
      logger('WARM_TRANSFER', { payload, response });

      updateCall(conferenceName, {
        warmTransferredToNum: phoneNumTo,
        state: CallStateState.TRANSFERRING,
        timestamps: [{
          action: TimestampAction.TRANSFER_TO_CLIENT,
          timestamp: Date.now(),
        }]
      })
    });
  }

  const transferToVm = (toVmBoxName: string, clientName?: string) => {
    if (thunkDispatch(hasProcessingCall())) return
    if (!toVmBoxName) {
      alert("Unable to find vm name");
      return;
    }

    const activeCall = thunkDispatch(getActiveCall())
    if (!activeCall) return;

    const { conferenceName }  = activeCall
    updateCall(conferenceName, { isProcessing: true })

    const payload = {
      conferenceName,
      leaveType: 'transferToVoicemail',
      toVmBoxName,
      clientName,
    };

    socket?.emit("LEAVE_CONFERENCE_OPERATOR", { payload: payload }, function (response: any) {
      logger('LEAVE_CONFERENCE_OPERATOR', { payload, response });
    });
  }

  const stopTransfer = (conferenceName: string) => {
    if (thunkDispatch(hasProcessingCall())) return
    const call = thunkDispatch(getCallByConferenceName(conferenceName))
    if (!call) return

    updateCall(conferenceName, { isProcessing: true, joinedAt: Date.now() })

    const payload = {
      conferenceName,
    }

    socket?.emit("STOP_TRANSFER", { payload: payload }, function (response: any) {
      logger('STOP_TRANSFER', { payload, response });

      if (call.callerLeftWarmTransfer) {
        return
      }

      updateCall(conferenceName, {
        warmTransferredTo: undefined,
        warmTransferredToNum: undefined,
        extensionsDialed: undefined,
        state: CallStateState.ANSWERED,
        timestamps: [{
          action: TimestampAction.ANSWER,
          timestamp: Date.now(),
        }]
      })
    });
  }

  const sendDigits = (num: Dtmf) => {
    const activeCall = thunkDispatch(getActiveCall())
    if (!activeCall) return

    // @ts-ignore _activeCall is private, but it's way better than manually maintaining the active call on our app
    const twilioCall = device?._activeCall
    if (!twilioCall) return

    twilioCall.sendDigits(num)
    const tone = dtmfPlayer?.playDtmf(num)
    if (tone) tone.stop(0.1)
  }

  const onCallConnectError = (error: any) => {
    const errorMessage = error?.message || ''
    let snackbarMessage = 'Failed to connect into the call.'

    switch (true) {
      case errorMessage.includes('CC001'):
      case errorMessage.includes('31005'):
      case errorMessage.includes('53001'):
        snackbarMessage = 'Failed to reach Twilio server. Please retry in a bit.'
        break;
    
      case errorMessage.includes('31402'):
        snackbarMessage = 'There is an issue with your audio devices. You cannot make calls until this issue is resolved.'
        break;

      default:
        break;
    }

    dispatch(setSnackbar({
      message: snackbarMessage,
      severity: SnackbarSeverity.ERROR
    }))
    device?.disconnectAll()
    logger('An error has occured while connecting to a call', errorMessage)
  }

  const notifyCallConnected = (conferenceName: string, callSid: string) => {
    const payload = { conferenceName, callSid }

    socket?.emit("CALL_CONNECTED", { payload }, function (response: any) {
      logger('CALL_CONNECTED', { payload, response });
      if (response?.callEnded) {
        device?.disconnectAll()
        removeCall(conferenceName)
      }
    });
  }

  const notifyCallFailed = (conferenceName: string, cc_reconnect = '', errorMessage: string) => {
    const payload = { conferenceName, cc_reconnect, errorMessage }

    socket?.emit("CALL_FAILED", { payload }, function (response: any) {
      logger('CALL_FAILED', { payload, response });
    });
  }

  return {
    addUnansweredCall,
    callJoined,
    callAnswered,
    callStarted,
    updateCall,
    removeCall,
    joinCall,
    listenToCall,
    outboundCall,
    holdCall,
    leaveCall,
    requeueCall,
    toggleWarmTransfer,
    transferToVm,
    stopTransfer,
    outboundOrTransfer,
    addOutboundCall,
    sendDigits,
    dialExtension,
    coldTransfer,
    callTaken,
  };
}

export default useCallFunctions