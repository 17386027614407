import { useDispatch } from "react-redux";
import { getActiveConferences, getUnansweredConferences, getUserConferences } from "api/conferences";
import useCallFunctions from "./useCallFunctions";
import { ActiveCall, CallStateState, prepareFetchedCalls } from "utils/calls";
import { addCalls, clearCalls } from "reducers/slices/callReducer";
import { claimSlot, setActiveCall, setOtherUserCalls, setSelectedCall } from "reducers/slices/callSlotReducer";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "context/socket-context";
import { logger, ViewRoute } from "utils/utils";
import { setNoConnection } from "reducers/slices/UIReducer";
import { AppThunkDispatch } from "reducers/types";
import { useAppSelector } from "reducers/hooks";
import { getActiveCall, getCallByConferenceName } from "reducers/thunks/callThunks";
import { setAcceptEvents } from "reducers/slices/eventReducer";
import { useLocation } from "react-router-dom";

const useConnectionHandler = () => {
  const socket = useContext(SocketContext)?.socket
  const { addUnansweredCall, joinCall } = useCallFunctions()
  const [hasDisconnected, setHasDisconnected] = useState<boolean>(false)
  const user = useAppSelector(state => state.user.user);
  const callEnabled = useAppSelector(state => state.ui.isCallEnabled)
  const dispatch = useDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch
  const location = useLocation()

  useEffect(() => {
    let interval: NodeJS.Timer

    if (user?.details?.id) {
      getUserCalls()

      if (callEnabled) {
        const refreshIntervalSeconds = process.env.REACT_APP_CALLS_REFRESH_SECONDS ? parseInt(process.env.REACT_APP_CALLS_REFRESH_SECONDS) : 30
        interval = setInterval(() => {
          getUserCalls()
        }, refreshIntervalSeconds * 1000)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.details?.id, callEnabled])

  useEffect(() => {
    const onDisconnect = () => {
      setHasDisconnected(true)
    }

    if (socket?.connected) {
      socket.on('disconnect', onDisconnect)
    }

    return () => {
      socket?.off('disconnect', onDisconnect)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket?.connected])

  useEffect(() => {
    if (hasDisconnected) {
      socket?.once('connect', () => {
        getUserCalls(true)
        setHasDisconnected(false)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDisconnected, callEnabled])

  const getUserCalls = async (isReconnected = false) => {
    const allConferenceNames = new Set<string>()

    const [res, res2, res3] = await Promise.all([
      getUserConferences(),
      getUnansweredConferences(),
      location.pathname === ViewRoute.ACTIVITY_VIEW ? getActiveConferences() : undefined,
    ])
    // Get user calls
    let calls = prepareFetchedCalls(res.data)
    calls = calls.filter(e => e.state !== CallStateState.UNANSWERED)
    dispatch(addCalls({ calls }))

    calls.forEach(call => allConferenceNames.add(call.conferenceName))

    const activeCall = calls.find(call => ActiveCall.includes(call.state))
    if (activeCall) {
      const existingActiveCall = thunkDispatch(getActiveCall())
      if (!existingActiveCall) {
        // Check if synced call is newer than local
        const call = thunkDispatch(getCallByConferenceName(activeCall.conferenceName))
        if (!call || ((activeCall.updatedAt || 0) >= (call.updatedAt || 0))) {
          dispatch(setActiveCall(activeCall))
          dispatch(setSelectedCall(activeCall))
    
          if (callEnabled) {
            logger('Reconnecting to an active call', activeCall)
            joinCall(activeCall.conferenceName, true)
          }
          dispatch(claimSlot({ conferenceName: activeCall.conferenceName, autoSelect: true })) 
        }
      }
    }

    const otherCalls = calls.filter(call => !ActiveCall.includes(call.state))
    otherCalls.forEach(call => dispatch(claimSlot({ conferenceName: call.conferenceName })))


    // Get unanswered calls
    res2.data.forEach((conference: any) => {
      logger('Adding unanswered call', conference)
      addUnansweredCall(conference.conferenceName, conference)
      allConferenceNames.add(conference.conferenceName)
    })


    if (res3) {
      const _otherUsersCalls = prepareFetchedCalls(res3.data)
      const otherUsersCalls = _otherUsersCalls.filter(e => e.conferenceJoinedBy !== user?.userId)
      dispatch(addCalls({ calls: otherUsersCalls }))
      dispatch(setOtherUserCalls(res3.data))
      otherUsersCalls.forEach(e => allConferenceNames.add(e.conferenceName))
    }


    // CCAA-1214 Only clear calls on reconnect, not on first load
    if (isReconnected) {
      dispatch(clearCalls(Array.from(allConferenceNames)))
    }

    dispatch(setAcceptEvents(true))
    dispatch(setNoConnection(false))
  }

  return null;
}

export default useConnectionHandler