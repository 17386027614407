import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import jwt_decode from "jwt-decode";
import { Alert, Box, CircularProgress, Collapse, Grid, Paper, Skeleton, Snackbar, Typography } from "@mui/material"
import { getForm, getFormContacts, submitForm } from "api/forms";
import { AI_CHECKED, ANSWER_ID_FIELDS, CALL_SID_QUESTION, CONTACTS_QUESTION, FieldType, FormContact, FormDetails, FormQuestion, FormSubmission, isValidCallSid, QuestionCondition, REGARDING_DELIMITER, SPECIAL_FIELDS, TEXT_FIELDS } from "./utils";
import Question from "./components/Question";
import AnimatedButton from "components/buttons/AnimatedButton";
import { logger } from "utils/utils";
import { isEmpty } from "lodash";
import { SnackbarSeverity, SnackbarState } from "reducers/slices/UIReducer";
import { InView } from "react-intersection-observer";
import { getLoggedInUser } from "reducers/thunks/userThunk";
import { useAppDispatch } from "reducers/hooks";
import { AppThunkDispatch } from "reducers/types";

const QuestionMemo = React.memo(Question, (prevProps, nextProps) => {
  return (
    prevProps.question.id === nextProps.question.id &&
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error
  )
})

interface IProps {
  formId?: string | null,
  token?: string | null,
  callerNumber?: string | null,
  callerName?: string | null,
  createdByName?: string | null,
  contactID?: string | null,
  type?: string | null,

  isRendered: boolean,
  afterSubmit: (callerNumber: string, accountName: string) => void,
}

const AbbyForm: FC<IProps> = (props) => {
  const { formId, token, callerNumber, type, isRendered, afterSubmit, contactID } = props;
  const [snackbar, setSnackbar] = useState<SnackbarState>({ isOpen: false, message: '', severity: SnackbarSeverity.SUCCESS })
  const [formDetails, setFormDetails] = useState<FormDetails | null>(null)
  const [otherForms, setOtherForms] = useState<Record<string, FormDetails>>({})
  const [formAnswers, setFormAnswers] = useState<Record<string, string>>({})
  const [formErrors, setFormErrors] = useState<Record<string, string>>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingSuggestion, setIsLoadingSuggestion] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const hasAnswerChangedSinceLastSuggestion = useRef<boolean>(false)
  const dispatch = useAppDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch

  const [callerName, callSid] = useMemo(() => {
    const [callerName, callSid] = (props.callerName || '').split('***')

    return [
      callerName || '',
      callSid || '',
    ]
  }, [props.callerName])

  const companyId = useMemo(() => {
    let companyId = ''
    if (token) {
      try {
        const tokenObj: Record<string, any> = jwt_decode(token)
        Object.entries(tokenObj).forEach(([key, value]) => {
          if (value === 'id') {
            companyId = key
          }
        })
      } catch (error) {
        console.log(error)
      }
    }

    return companyId
  }, [token])

  const internalFormId = useMemo(() => {
    return formId + companyId + callSid
  }, [formId, companyId, callSid])

  const formSubject = useMemo(() => {
    if (!formDetails) return ''

    let formSubject = formDetails.subject
    if (!formSubject) return ''

    if (type) formSubject = formSubject.replace('{Call Type}', type)
    if (callerName) formSubject = formSubject.replace('{Caller Name}', callerName)
    if (callerNumber) formSubject = formSubject.replace('{Caller Number}', callerNumber)

    return formSubject
  }, [formDetails, callerName, callerNumber, type])

  const askAIForms = useMemo(() => {
    return type === 'Outgoing' ? false : !!formDetails?.askAIForms
  }, [formDetails?.askAIForms, type])

  useEffect(() => {
    if (companyId && formId && token) {
      setIsLoading(true)
      getForm(companyId, formId).then((res) => {
        const formDetails: FormDetails = res.data

        setFormDetails({ ...formDetails, childForms: undefined })

        if (formDetails.childForms) {
          setOtherForms((forms) => ({
            ...forms,
            ...formDetails.childForms,
          }))
        }
      }).finally(() => {
        setIsLoading(false)
      })
    }
  }, [companyId, formId, token])

  useEffect(() => {
    if (callSid) {
      setFormAnswers((formAnswers) => ({
        callSid,
        // If callSid exists, then we should not override it
        ...formAnswers,
      }))
    }
  }, [callSid])

  // Retrieve cached data
  useEffect(() => {
    if (internalFormId) {
      const cachedAnswers = localStorage.getItem(internalFormId)
      if (cachedAnswers) {
        try {
          const formAnswers = JSON.parse(cachedAnswers)
          setFormAnswers((currentAnswers) => ({
            ...currentAnswers,
            ...formAnswers,
          }))
        } catch (error) {
          logger('Error on parsing form answers json', error)
        }
      }
    }
  }, [internalFormId])

  const questions: FormQuestion[] = useMemo(() => {
    const questions: FormQuestion[] = []

    const getFormQuestions = (form: FormDetails, conditions: QuestionCondition[] = []) => {
      if (form.instructions && form.id !== formDetails?.id) {
        // @ts-ignore special case
        questions.push({ fieldType: FieldType.Instruction, instructions: form.instructions, conditions: conditions.slice() })
      }

      const formQuestions = form.questions.sort((a, b) => a.questionNumber - b.questionNumber)
      formQuestions.forEach(question => {
        const _question: FormQuestion = {
          ...question,
          conditions: conditions.slice(),
        }

        if (question.fieldType === FieldType.Custom) {
          let dictionary = form.dictionaries?.find(e => e.id === question.dictionaryId)
          if (!dictionary) {
            dictionary = formDetails?.dictionaries.find(e => e.id === question.dictionaryId)

            if (!dictionary) {
              dictionary = Object.values(otherForms)
                .find(form => !!form.dictionaries?.find(e => e.id === question.dictionaryId))
                ?.dictionaries.find(e => e.id === question.dictionaryId)
            }
          }
          _question.clientDictionaryRecords = dictionary?.clientDictionaryRecords || []
        }

        questions.push(_question)

        if (question.redirectToForm) {
          const otherForm = otherForms[question.redirectToForm]
          if (otherForm) {
            getFormQuestions(otherForm, conditions.concat({ conditionalId: question.id}))
          } else {
            // @ts-ignore special case
            questions.push({
              fieldType: FieldType.Loading,
              conditions: conditions.concat({ conditionalId: question.id}),
              formId: question.redirectToForm,
            })
          }
        }

        if (question.picklist) {
          question.picklist.forEach(picklist => {
            if (picklist.redirectToForm) {
              const otherForm = otherForms[picklist.redirectToForm]
              if (otherForm) {
                getFormQuestions(otherForm, conditions.concat({ conditionalId: question.id, conditionalValue: picklist.id }))
              } else {
                // @ts-ignore special case
                questions.push({
                  fieldType: FieldType.Loading,
                  conditions: conditions.concat({ conditionalId: question.id, conditionalValue: picklist.id }),
                  formId: picklist.redirectToForm,
                })
              }
            }
          })
        }
      })
    }

    if (formDetails) {
      getFormQuestions(formDetails)

      // Add mandatory questions
      const shouldHideCallSid = isValidCallSid(callSid)
      questions.unshift({
        ...CALL_SID_QUESTION,
        hideOnUi: shouldHideCallSid,
      })
      questions.push(CONTACTS_QUESTION)
    }

    return questions
  }, [formDetails, otherForms, callSid])

  const states: string[] = useMemo(() => {
    let states: string[] = formDetails?.usaStates || []
    if (!states.length) {
      const otherFormsWithStates = Object.values(otherForms).find(e => !!e.usaStates)
      if (otherFormsWithStates) {
        states = otherFormsWithStates.usaStates
      }
    }

    return states
  }, [formDetails, otherForms])

  const contacts: FormContact[] = useMemo(() => {
    let contacts: FormContact[] = formDetails?.contacts || []
    if (!contacts.length) {
      const otherFormsWithStates = Object.values(otherForms).find(e => !!e.contacts)
      if (otherFormsWithStates) {
        contacts = otherFormsWithStates.contacts
      }
    }

    return contacts.map(contact => ({
      ...contact,
      name: contact.name + (contact.catchAll ? ' [C]' : '')
    }))
  }, [formDetails, otherForms])

  const contactsWithEmail: FormContact[] = useMemo(() => {
    return contacts.filter(contact => contact.email || contact.additionalEmails)
  }, [contacts])

  useEffect(() => {
    if (contactID && contacts.length) {
      if (contacts.find(e => e.id === contactID)) {
        setFormAnswers((formAnswers) => ({
          [CONTACTS_QUESTION.id]: contactID,
          // If contactID exists, then we should not override it
          ...formAnswers,
        }))
      }
    }
  }, [contactID, contacts])

  const handleChange = useCallback((questionId: string, value: string) => {
    // <questionId, value>
    const newAnswers: Record<string, string> = {
      [questionId]: value
    }

    const question = questions.find(e => e.id === questionId)

    // Find and update answers for duplicate questions
    questions.forEach(e => {
      // Original question has been populated, skip this
      if (e.id === questionId) return

      if (!question?.question || !e.question) return
      if (question.fieldType === FieldType.Markbox) return

      // Question is different, skip this
      if (e.question.toLowerCase().trim() !== question.question.toLowerCase().trim()) return

      // We are just clearing the answer, any fields can be cleared
      if (!value) {
        newAnswers[e.id] = value
        return
      }

      // Same field type, carry over the answer
      if ((question.fieldType === e.fieldType && ![FieldType.Picklist, FieldType.Custom].includes(question.fieldType)) ||
        (TEXT_FIELDS.includes(question.fieldType) && TEXT_FIELDS.includes(e.fieldType)) ||
        (question.fieldType === FieldType.Custom && question.dictionaryId === e.dictionaryId)
      ) {
        newAnswers[e.id] = value
        return
      }

      let newAnswer = value

      // Get transformed newAnswer depending on field type
      switch (true) {
        case question.fieldType === FieldType.Picklist:
          const selectedOption = (question.picklist || []).find(e => e.id === value)
          if (selectedOption) {
            newAnswer = selectedOption.value
          }
          break;

        case question.fieldType === FieldType.Custom:
          const selectedDict = (question.clientDictionaryRecords || []).find(e => e.id === value)
          if (selectedDict) {
            newAnswer = selectedDict.name
          }
          break;
        
        case question.fieldType === FieldType.Contacts:
          const selectedContact = contacts.find(e => e.id === value)
          if (selectedContact) {
            newAnswer = selectedContact.name
          }
          break;

        default:
          break;
      }

      // Implement the answer differently based on duplicate question's type
      switch (true) {
        case TEXT_FIELDS.includes(e.fieldType):
          newAnswers[e.id] = value
          break;

        case e.fieldType === FieldType.Contacts:
          const selectedContact = contacts.find(e => e.name.toLowerCase().trim() === newAnswer.toLowerCase().trim())
          if (selectedContact) {
            newAnswers[e.id] = selectedContact.id
          }
          break;

        case e.fieldType === FieldType.Picklist:
          // Look for same option label on the duplicate question
          const matchingAnswer = e.picklist?.find(p => p.value.toLowerCase().trim() === newAnswer.toLowerCase().trim())
          if (matchingAnswer) {
            newAnswers[e.id] = matchingAnswer.id
          }
          break;

        case e.fieldType === FieldType.Custom:
          // Look for same option label on the duplicate question
          const matchingDict = e.clientDictionaryRecords?.find(e => e.name.toLowerCase().trim() === newAnswer.toLowerCase().trim())
          if (matchingDict) {
            newAnswers[e.id] = matchingDict.id
          }
          break;

        case e.fieldType === FieldType.Markbox:
          if (newAnswer) {
            newAnswers[e.id] = AI_CHECKED
          }
          break;

        case e.fieldType === FieldType.States:
          const selectedState = states.find(state => state.toLowerCase().trim() === newAnswer.toLowerCase().trim())
          if (selectedState) {
            newAnswers[e.id] = selectedState
          }
          break;
      
        default:
          break;
      }
    })

    setFormAnswers((formAnswers) => {
      const newFormAnswers = { ...formAnswers, ...newAnswers }

      localStorage.setItem(internalFormId, JSON.stringify(newFormAnswers))
      return newFormAnswers
    })
    if (questionId !== CONTACTS_QUESTION.id) {
      hasAnswerChangedSinceLastSuggestion.current = true
    }

    setFormErrors((formErrors) => {
      const newFormErrors = { ...formErrors }

      Object.keys(newAnswers).forEach(id => {
        newFormErrors[id] = ''
      })

      return newFormErrors
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalFormId, questions])

  const loadFormIfMissing = useCallback((formId?: string) => {
    if (!formId) return
    if (otherForms[formId]) return

    if (token) {
      getForm(companyId, formId).then((res) => {
        const loadedForm: FormDetails = res.data
        setOtherForms((forms) => ({
          ...forms,
          ...(loadedForm.childForms || {}),
          [formId]: { ...loadedForm, childForms: undefined }
        }))
      })
    }
  }, [otherForms, companyId, token])

  const getContactsSuggestion = () => {
    if (!companyId || !formId) return
    if (!hasAnswerChangedSinceLastSuggestion.current) return
    hasAnswerChangedSinceLastSuggestion.current = false

    const questionsToSubmit = questions.filter(e => (shouldShowQuestion(e) || e.hideOnUi) && !SPECIAL_FIELDS.includes(e.fieldType))
    const answeredQuestions = questionsToSubmit.filter(e =>
      formAnswers[e.id] &&
      e.id !== CALL_SID_QUESTION.id &&
      e.id !== CONTACTS_QUESTION.id
    )

    const _questions = answeredQuestions
    .filter(e => !formAnswers[e.id].startsWith(AI_CHECKED))
    .map(e => ({
      answer: ANSWER_ID_FIELDS.includes(e.fieldType) ? null : formAnswers[e.id].trim(),
      answerId: ANSWER_ID_FIELDS.includes(e.fieldType) ? formAnswers[e.id] : null,
      questionId: e.id,
    }))

    setIsLoadingSuggestion(true)
    getFormContacts(companyId, formId, { formId, questions: _questions }).then((res) => {
      const suggestedContacts = res.data as FormContact[]
      setFormAnswers((formAnswers) => {
        const newFormAnswers = {
          ...formAnswers,
          [CONTACTS_QUESTION.id]: [
            ...(formAnswers[CONTACTS_QUESTION.id] || '').split(','),
            ...suggestedContacts.map(e => e.id),
          ].join(','),
        }

        localStorage.setItem(internalFormId, JSON.stringify(newFormAnswers))

        return newFormAnswers
      })

      setIsLoadingSuggestion(false)
    })
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    const formErrors: Record<string, string> = {}
    let firstErrorId = ''

    const questionsToSubmit = questions.filter(e => (shouldShowQuestion(e) || e.hideOnUi) && !SPECIAL_FIELDS.includes(e.fieldType))

    questionsToSubmit.forEach(question => {
      if (askAIForms) {
        // Special case for regarding
        if (question.question.toLowerCase().includes('regarding')) {
          const [marked, addDisclaimer, disclaimer] = formAnswers[question.id]?.split(REGARDING_DELIMITER) || ['', '', '']
          const errors = [
            !marked ? 'This field is required' : '',
            '',
            addDisclaimer && !disclaimer ? 'This field is required' : '',
          ]
          if (errors.some(e => !!e)) {
            formErrors[question.id] = errors.join(REGARDING_DELIMITER)

            if (!firstErrorId) {
              firstErrorId = question.id
            }
          }
          return
        }
      }

      if (question.isRequired && !formAnswers[question.id]?.trim()) {
        formErrors[question.id] = 'This field is required.'
      }

      if (question.id === 'callSid' && formAnswers[question.id]) {
        if (formAnswers[question.id].trim().length !== 34) {
          formErrors[question.id] = 'Call SID must be 34 characters long.'
        }
        if (!formAnswers[question.id].startsWith('CA')) {
          formErrors[question.id] = 'Call SID must starts with CA.'
        }
      }

      if (formErrors[question.id] && !firstErrorId) {
        firstErrorId = question.id
      }
    })

    if (!isEmpty(formErrors)) {
      const questionEl = document.getElementById(firstErrorId)
      if (questionEl) {
        questionEl.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }

      setFormErrors(formErrors)
      setIsSubmitting(false)
      setSnackbar({ message: 'Please check for required fields', isOpen: true, severity: SnackbarSeverity.ERROR })
      return
    }

    if (token) {
      const answeredQuestions = questionsToSubmit.filter(e =>
        formAnswers[e.id] &&
        e.id !== CALL_SID_QUESTION.id &&
        e.id !== CONTACTS_QUESTION.id
      )

      const regardingQuestion = answeredQuestions.find(e => e.question.toLowerCase().includes('regarding'))
      let disclaimer: string | undefined = undefined
      if (regardingQuestion) {
        disclaimer = formAnswers[regardingQuestion.id]?.split(REGARDING_DELIMITER)[2]
      }

      const user = thunkDispatch(getLoggedInUser())

      const payload: FormSubmission = {
        receptionistId: user?.userId || '',
        callSid: formAnswers[CALL_SID_QUESTION.id],
        askAIForms,
        disclaimer,
        contacts: formAnswers[CONTACTS_QUESTION.id].split(','),
        subject: formDetails?.subject || '',
        questions: answeredQuestions.map(e => e.id),
        questionAnswerPairs: answeredQuestions
          .filter(e => !formAnswers[e.id].startsWith(AI_CHECKED))
          .map(e => ({
            answer: ANSWER_ID_FIELDS.includes(e.fieldType) ? undefined : formAnswers[e.id].trim(),
            answerId: ANSWER_ID_FIELDS.includes(e.fieldType) ? formAnswers[e.id] : undefined,
            question: e.id,
            fieldType: e.fieldType,
          }))
      }
      submitForm(companyId, payload).then((res) => {
        localStorage.removeItem(internalFormId)
        setSnackbar({ message: 'Form submitted!', isOpen: true, severity: SnackbarSeverity.SUCCESS})
        afterSubmit(callerName || '', formDetails?.accountName || '')
        // Intentionally not setting isSubmitting to false as this form should be closed.
      }).catch((err) => {
        setSnackbar({ message: err.message, isOpen: true, severity: SnackbarSeverity.ERROR })
        setIsSubmitting(false)
      })
    }
  }

  if (!isRendered) {
    return null
  }

  const shouldShowQuestion = (question: FormQuestion): boolean => {
    if (question.hideOnUi) return false

    if (!question.conditions?.length) return true

    return question.conditions.every(condition => {
      if (condition.conditionalValue) {
        return formAnswers[condition.conditionalId] === condition.conditionalValue
      }
      return !!formAnswers[condition.conditionalId]
    })
  }

  const isMissingData = !formId || !token || !companyId
  return (
    <Box
      sx={{
        width: 'calc(100% - 20%)',
        minHeight: 'calc(100% - 64px)',
        backgroundColor: 'rgb(237, 242, 247)',
        px: '10%',
        display: 'flex',
        justifyContent: 'center',
        py: 4,
        '& p': {
          marginTop: '4px',
          marginBottom: '4px',
        },
        '& p:first-child': {
          margin: 0,
        }
      }}
    >
      <Snackbar
        open={snackbar.isOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ mt: '-12px' }}
      >
        <Alert
          onClose={() => setSnackbar((snackbar) => ({ ...snackbar, isOpen: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <Paper sx={{ width: '100%', minWidth: 1100, maxWidth: 1200 }}>
        {isLoading &&
          <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress sx={{ p: 6 }} size={300} />
          </Box>
        }

        {!isLoading &&
          <Box py={4} display="flex" justifyContent="center">
            <Grid container sx={{ p: 1 }} columnSpacing={2}>
              {isMissingData &&
                <>
                  <Grid item xs={3} />
                  <Grid item xs={6}>
                    {!formId && <Typography>Missing form id</Typography>}
                    {!token && <Typography>Missing token</Typography>}
                    {!companyId && <Typography>Missing company id</Typography>}
                  </Grid>
                  <Grid item xs={3} />
                </>
              }

              {formDetails &&
                <>
                  <Grid item xs={3} />
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>{formDetails.accountName} - {formSubject}</Typography>

                    <Typography component="div" sx={{ mt: 2 }}>
                      <div dangerouslySetInnerHTML={{ __html: formDetails.instructions || '' }}></div>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} />

                  <Grid item xs={3} />
                  <Grid item xs={6}>
                    {callerNumber && <Typography sx={{ fontWeight: 500 }}>Caller: {callerNumber}</Typography>}
                  </Grid>
                  <Grid item xs={3} />

                  <Grid item xs={12} sx={{ mt: 4 }} />

                  {questions.filter(e => !e.hideOnUi).map((question, index) => (
                    <Grid key={index} item xs={12}>
                      {question.id === CONTACTS_QUESTION.id &&
                        <InView onChange={(inView) => inView && getContactsSuggestion()}>
                          <Box></Box>
                        </InView>
                      }

                      {!!question.conditions?.length &&
                        <Collapse
                          in={shouldShowQuestion(question)}
                          mountOnEnter
                          appear
                          onEnter={question.formId ? () => loadFormIfMissing(question.formId) : undefined}
                        >
                          {question.fieldType === FieldType.Loading &&
                            <Grid container sx={{ mb: 3 }}>
                              <Grid item xs={3} />
                              <Grid item xs={6}>
                                <Skeleton variant="rectangular" width="100%" height={80} />
                              </Grid>
                            </Grid>
                          }
                          {question.fieldType === FieldType.Instruction &&
                            <Grid container sx={{ mb: 3 }} spacing={2}>
                              <Grid item xs={3} />
                              <Grid item xs={6}>
                                <Typography component="div">
                                  <div dangerouslySetInnerHTML={{ __html: question.instructions || '' }}></div>
                                </Typography>
                              </Grid>
                            </Grid>
                          }

                          {!SPECIAL_FIELDS.includes(question.fieldType) &&
                            <QuestionMemo
                              question={question}
                              handleChange={handleChange}
                              value={formAnswers[question.id] || ''}
                              error={formErrors[question.id]}
                              askAIForms={askAIForms}
                              states={question.fieldType === FieldType.States ? states : undefined}
                              contacts={[FieldType.Contacts, FieldType.ContactsMultiple, FieldType.Custom].includes(question.fieldType) ? contacts : undefined}
                            />
                          }
                        </Collapse>
                      }

                      {!question.conditions?.length &&
                        <QuestionMemo
                          question={question}
                          handleChange={handleChange}
                          value={formAnswers[question.id] || ''}
                          error={formErrors[question.id]}
                          askAIForms={askAIForms}
                          states={question.fieldType === FieldType.States ? states : undefined}
                          contacts={
                            question.id === CONTACTS_QUESTION.id ? contactsWithEmail :
                              [FieldType.Contacts, FieldType.ContactsMultiple, FieldType.Custom].includes(question.fieldType) ? contacts : undefined
                          }
                        />
                      }

                      {question.id === CONTACTS_QUESTION.id &&
                        <Grid container sx={{ mt: -3 }}>
                          <Grid item xs={3}></Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ fontWeight: 400 }}>
                              {isLoadingSuggestion && <i>Loading suggested contacts...</i>}
                              &nbsp;
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  ))}

                  <Grid item xs={3} />
                  <Grid item xs={6} container justifyContent="flex-end">
                    <AnimatedButton
                      onClick={handleSubmit}
                      label={isSubmitting ? 'Submitting...' : 'Submit'}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      sx={{
                        width: 130,
                        borderRadius: '15px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={3} />
                </>
              }
            </Grid>
          </Box>
        }

      </Paper>
    </Box>
  )
}

export default AbbyForm;
