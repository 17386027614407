import { Close } from "@mui/icons-material";
import {
  Box, Dialog, DialogContent,
  IconButton,
  ImageList,
  ImageListItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setContactForStatusList, setContactStatusForEditStatus } from "reducers/slices/UIReducer";
import { useAppSelector } from 'reducers/hooks';
import { getContactName, ScheduledContactStatus } from 'utils/accounts';
import { useEffect, useState } from "react";
import ContactStatusAlert from "./contactItem/ContactStatusAlert";
import { getScheduledStatuses } from "api/contacts";
import AnimatedButton from "components/buttons/AnimatedButton";
import ContactStatusDeleteDialog from "./ContactStatusDeleteDialog";

const ContactStatusListDialog = () => {
  const contactForStatusList = useAppSelector(state => state.ui.contactForStatusList)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [contactStatuses, setContactStatuses] = useState<ScheduledContactStatus[]>([])
  const [isConfirmingDelete, setIsConfirmingDelete] = useState<ScheduledContactStatus | null>(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (contactForStatusList) {
      getStatuses()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactForStatusList])

  const getStatuses = () => {
    if (contactForStatusList) {
      setIsLoading(true)
      getScheduledStatuses(contactForStatusList.accountId, contactForStatusList.id).then((res) => {
        setContactStatuses(res.data || [])
      }).finally(() => setIsLoading(false))
    }
  }

  const handleDeleteCallback = () => {
    setIsConfirmingDelete(null)
    getStatuses()
  }

  const handleNew = () => {
    if (contactForStatusList) {
      dispatch(setContactStatusForEditStatus({
        contact: contactForStatusList,
        // @ts-ignore
        contactStatus: { id: 'new' },
      }))
    }
  }

  const handleClose = () => {
    dispatch(setContactForStatusList(null))
  }

  return (
    <Dialog open={!!contactForStatusList} onClose={handleClose} maxWidth={false}>
      <ContactStatusDeleteDialog
        contactStatus={isConfirmingDelete}
        contact={contactForStatusList}
        closeDelete={() => setIsConfirmingDelete(null)}
        onDeleteCallback={handleDeleteCallback}
      />

      <DialogContent
        sx={{
          width: 740,
          minHeight: 400,
          pb: 1,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          overflowX: 'hidden',
          boxSizing: 'border-box',
        }}
      >
        <Box position="relative" width="100%">
          <Typography sx={{ fontWeight: 600, fontSize: 20, pr: 4 }}>
            {contactForStatusList ? getContactName(contactForStatusList) : ''}
            {' - Scheduled Call Status'}
          </Typography>
          <IconButton sx={{ position: 'absolute', right: -4, top: -4 }} onClick={handleClose}>
            <Close sx={{ color: 'black' }} />
          </IconButton>
        </Box>

        <Stack mt={2} direction="row" justifyContent="flex-end">
          <AnimatedButton
            onClick={handleNew}
            label={'+ New Scheduled Status'}
            sx={{
              width: 200,
              borderRadius: '15px',
            }}
          />
        </Stack>

        {isLoading &&
          <ImageList variant="masonry" cols={2} gap={8}>
            {[...new Array(6)].map((e, i) => (
              <ImageListItem key={i}>
                <Skeleton variant="rectangular" height={80} sx={{ borderRadius: '5px' }} />
              </ImageListItem>
            ))}
          </ImageList>
        }

        {(!isLoading && contactForStatusList) &&
          <ImageList variant="masonry" cols={2} gap={8} sx={{ scrollbarGutter: 'stable', pr: 1, mr: '-18px' }}>
            {contactStatuses.map(contactStatus => (
              <ImageListItem key={contactStatus.id}>
                <Box key={contactStatus.id}>
                  <ContactStatusAlert
                    contactStatus={contactStatus}
                    // @ts-ignore
                    onEdit={() => dispatch(setContactStatusForEditStatus({ contactStatus, contact: contactForStatusList }))}
                    onDelete={() => setIsConfirmingDelete(contactStatus)}
                  />
                </Box>
              </ImageListItem>
            ))}
          </ImageList>
        }

        {(!isLoading && contactForStatusList && !contactStatuses.length) &&
          <Typography textAlign="center" sx={{ width: '100%', mt: 14 }}>No Scheduled Status Found.</Typography>
        }

      </DialogContent>
    </Dialog>
  );
};

export default ContactStatusListDialog;
