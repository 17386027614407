import { useContext, useEffect } from "react";
import { logger } from "utils/utils";
import { useAppDispatch } from "reducers/hooks";
import { SocketContext } from "context/socket-context";
import { setIsCallEnabled, setTwilioLoading } from "reducers/slices/UIReducer";

/**
 * Ping socket to ask if this connection can receive call
 */
const useCanReceiveCall = () => {
  const dispatch = useAppDispatch()
  const socket = useContext(SocketContext)?.socket

  useEffect(() => {
    socket?.emit("CAN_RECEIVE_CALL", { payload: {} }, function (response: any) {
      logger('CAN_RECEIVE_CALL', { response });
      if (response.canReceiveCall) {
        dispatch(setIsCallEnabled(true))
      } else {
        dispatch(setTwilioLoading(false))
      }
    });
  }, [socket, dispatch])

  return null
}

export default useCanReceiveCall