import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Typography } from '@mui/material';
import SearchBar from './SearchBar';
import UserMenu from '../settings/UserMenu';
import { useContext, useState } from 'react';
import { Close } from '@mui/icons-material';
import { SocketContext } from 'context/socket-context';
import { logger } from 'utils/utils';

export const CallDisabledBar = () => {
  const socket = useContext(SocketContext)?.socket
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const onActivateTab = () => {
    socket?.emit("SWITCH_ACTIVE_TAB", {}, function (response: any) {
      logger('SWITCH_ACTIVE_TAB', { response })
      window.location.reload()
    });
  }

  return (
    <Box display="flex" alignItems="flex-end">
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>
          {'Receive Calls on This Tab'}
          <IconButton
            onClick={() => setShowDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#E80B0B',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ pr: 6 }}>
          <Typography>
            {`This will deactivate the tab which has calls enabled. You will start receiving calls on this tab instead. This will reload this tab. Are you sure you want to proceed?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={onActivateTab}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Box flexGrow={1} pr={1}>
        <Paper
          elevation={0}
          sx={{ borderRadius: '5px', px: 2, height: 80, display: 'flex', alignItems: 'center' }}
        >
          <Typography fontSize="26px" fontWeight={600}>Calls are disabled on this tab. Please use your other tab to receive and make calls.</Typography>
        </Paper>
      </Box>

      <Box width={508} mr={1}>
        <Button
          sx={{
            width: '100%',
            height: 36,
            mb: '6px',
            color: '#1033A5',
          }}
          onClick={() => setShowDialog(true)}
          size="small"
          variant="contained"
          disableElevation
        >
          Click here to receive calls on this tab
        </Button>
        <SearchBar />
      </Box>
      <UserMenu isReceptionist />
    </Box>
  );
}

export default CallDisabledBar;