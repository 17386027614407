import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CallStateState, TimestampAction, WarmTransferredTo } from "utils/calls";
import { subscribeToEvent } from "utils/socket/socket";
import useCallFunctions from "./useCallFunctions";
import { SocketContext } from "context/socket-context";
import { TwilioContext } from "context/twilio-context";
import { SnackbarSeverity, setIsCallEnabled, setSnackbar } from "reducers/slices/UIReducer";
import { addUserDetails, logout } from "reducers/slices/userReducer";
import { addOrUpdateUser } from "reducers/slices/activityViewReducer";
import { AppThunkDispatch } from "reducers/types";
import { stopAllProcessing } from "reducers/thunks/callThunks";
import { useAppSelector } from "reducers/hooks";
import { addPendingEvent, clearPendingEvent } from "reducers/slices/eventReducer";
import { logger } from "utils/utils";
import { clearAllCallTimeout } from "reducers/slices/callSlotReducer";

const useEventsListener = () => {
  const socket = useContext(SocketContext)?.socket
  const device = useContext(TwilioContext)?.device
  const { user } = useAppSelector(state => state.user);
  const acceptEvents = useAppSelector(state => state.event.acceptEvents);
  const pendingEvents = useAppSelector(state => state.event.pendingEvents);
  const dispatch = useDispatch()
  const thunkDispatch = dispatch as AppThunkDispatch
  const {
    addUnansweredCall,
    callJoined,
    callStarted,
    removeCall,
    updateCall,
    addOutboundCall,
    callTaken,
  } = useCallFunctions()

  // Please note that the events does not get updated when any states is updated
  // Please ensure that any functions called by the event listener does not depend on any state.
  useEffect(() => {
    if (socket) {
      if (!acceptEvents) {
        socket.onAny((eventName, data) => {
          logger('Adding pending event: ', { eventName, data })
          dispatch(addPendingEvent({ eventName, data }))
        })
      } else {
        subscribeToEvent(socket, "NEW_EMPTY_CONFERENCE", setConferenceToUnanswered);
        subscribeToEvent(socket, "NEW_OUTBOUND_CONFERENCE", addOutboundConference);
        subscribeToEvent(socket, "JOIN_CONFERENCE_OPERATOR", onJoinConferenceOperator);
        subscribeToEvent(socket, "CONFERENCE_STATUS_CHANGED", onConferenceStatusChanged);
        subscribeToEvent(socket, "CONFERENCE_NOTES_CHANGED", onConferenceNotesChanged);
        subscribeToEvent(socket, "UNANSWERED_CONFERENCE_CANCELLED", onUnansweredConferenceCancelled);
        subscribeToEvent(socket, "OUTBOUND_CALL_REJECTED", onOutboundCallRejected);
        subscribeToEvent(socket, "CONFERENCE_REQUEUED", onConferenceRequeued);
      }

      subscribeToEvent(socket, "ERROR_REPORT", onErrorReport);
      subscribeToEvent(socket, "INACTIVATE_TAB", onInactivateTab);
    }

    return () => {
      socket?.offAny()
      socket?.removeAllListeners('NEW_EMPTY_CONFERENCE')
      socket?.removeAllListeners('JOIN_CONFERENCE_OPERATOR')
      socket?.removeAllListeners('CONFERENCE_STATUS_CHANGED')
      socket?.removeAllListeners('CONFERENCE_NOTES_CHANGED')
      socket?.removeAllListeners('UNANSWERED_CONFERENCE_CANCELLED')
      socket?.removeAllListeners('OUTBOUND_CALL_REJECTED')
      socket?.removeAllListeners('CONFERENCE_REQUEUED')
      socket?.removeAllListeners('ERROR_REPORT')
      socket?.removeAllListeners('INACTIVATE_TAB')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, device, user, acceptEvents])

  useEffect(() => {
    if (socket && acceptEvents && pendingEvents.length) {
      pendingEvents.forEach(pendingEvent => {
        logger('Resolving pending event: ', { pendingEvent })
        switch (pendingEvent.eventName) {
          case "NEW_EMPTY_CONFERENCE":
            setConferenceToUnanswered(pendingEvent.data)
            break;
          case "NEW_OUTBOUND_CONFERENCE":
            addOutboundConference(pendingEvent.data)
            break;
          case "JOIN_CONFERENCE_OPERATOR":
            onJoinConferenceOperator(pendingEvent.data)
            break;
          case "CONFERENCE_STATUS_CHANGED":
            onConferenceStatusChanged(pendingEvent.data)
            break;
          case "CONFERENCE_NOTES_CHANGED":
            onConferenceNotesChanged(pendingEvent.data)
            break;
          case "UNANSWERED_CONFERENCE_CANCELLED":
            onUnansweredConferenceCancelled(pendingEvent.data)
            break;
          case "OUTBOUND_CALL_REJECTED":
            onOutboundCallRejected(pendingEvent.data)
            break;
          case "CONFERENCE_REQUEUED":
            onConferenceRequeued(pendingEvent.data)
            break;

          default:
            break;
        }
      })

      dispatch(clearPendingEvent())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, acceptEvents, pendingEvents])

  useEffect(() => {
    if (socket) {
      subscribeToEvent(socket, "USER_UPDATES", onUserUpdates);
    }

    return () => {
      socket?.removeAllListeners('USER_UPDATES')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, user])

  function setConferenceToUnanswered(data: any) {
    addUnansweredCall(data.payload.conferenceName, data.payload, true)
  }

  function addOutboundConference(data: any) {
    addOutboundCall(data.payload.conferenceName, data.payload)
  }

  function onJoinConferenceOperator(data: any) {
    const { conferenceName, conferenceJoinedBy } = data.payload

    callJoined(conferenceName, conferenceJoinedBy)
  }

  function onConferenceStatusChanged(data: any) {
    const conferenceName = data.payload.conferenceName

    // For calls of this users under these events, frontend performs an optimistic updates instead
    if (data.payload.conferenceJoinedBy !== user?.userId) {
      switch (data.payload.status) {
        case 'hold':
          updateCall(conferenceName, {
            state: CallStateState.ONHOLD,
            timestamps: [{
              action: TimestampAction.HOLD,
              timestamp: Date.now(),
            }]
          })
          break;

        default:
          break;
      }
    }

    switch (data.payload.status) {
      case 'started':
      case 'resumed':
        callStarted(conferenceName, data.payload.callSid)
        break;

      case 'warm-transferring':
        updateCall(conferenceName, {
          warmTransferredToNum: data.payload.warmTransferredToNum,
          state: CallStateState.TRANSFERRING,
          timestamps: [{
            action: TimestampAction.TRANSFER_TO_CLIENT,
            timestamp: Date.now(),
          }]
        })
        break;
      
      case 'warm-transfer':
        updateCall(conferenceName, {
          warmTransferredTo: WarmTransferredTo.CLIENT,
          transferEnabled: true,
          state: CallStateState.WITH_CLIENT,
          timestamps: [{
            action: TimestampAction.TRANSFER_TO_CLIENT,
            timestamp: Date.now(),
          }]
        })
        break;

      case 'toggled-warm-transfer':
        const transferredToCaller = data.payload.putOnHold === WarmTransferredTo.CLIENT
        updateCall(conferenceName, {
          warmTransferredTo: transferredToCaller ? WarmTransferredTo.CALLER : WarmTransferredTo.CLIENT,
          state: transferredToCaller ? CallStateState.WITH_CALLER : CallStateState.WITH_CLIENT,
          timestamps: [{
            action: transferredToCaller ? TimestampAction.TRANSFER_TO_CALLER : TimestampAction.TRANSFER_TO_CLIENT,
            timestamp: Date.now(),
          }]
        })
        break;

      case 'warm-transfer-hangup':
        updateCall(conferenceName, {
          callerLeftWarmTransfer: true,
        })
        if (data.payload.operatorId === user?.userId) {
          dispatch(setSnackbar({ message: 'Caller has left the call.', severity: SnackbarSeverity.ERROR }))
        }
        break;

      case 'stop-transfer':
        const { shouldHold } = data.payload
        const stopTransferTimestamps = [{
          action: TimestampAction.STOP_TRANSFER,
          timestamp: Date.now(),
        }]

        if (shouldHold) {
          stopTransferTimestamps.push({
            action: TimestampAction.HOLD,
            timestamp: Date.now(),
          })
        }
        updateCall(conferenceName, {
          warmTransferredTo: undefined,
          warmTransferredToNum: undefined,
          state: shouldHold ? CallStateState.ONHOLD : CallStateState.ANSWERED,
          joinedAt: Date.now(),
          timestamps: stopTransferTimestamps,
        })

        if (data.payload.operatorId === user?.userId) {
          const reason = data.payload.reasonStopped
          if (reason === 'not-picked-up') {
            dispatch(setSnackbar({ message: 'Client rejected or did not answer the call.', severity: SnackbarSeverity.ERROR }))
          } else if (reason === 'client-leave') {
            dispatch(setSnackbar({ message: 'Client has left the call.', severity: SnackbarSeverity.WARNING }))
          }
        }
        break;

      case 'finished':
      case 'call-transfer-completed':
        removeCall(conferenceName)
        break;

      case 'taken':
        callTaken(conferenceName, data.payload.takenById, data.payload.takenBy)
        break;

      default:
        break;
    }
  }

  function onConferenceNotesChanged(data: any) {
    updateCall(data.payload.conferenceName, {
      ...data.payload,
    })
  }

  function onUnansweredConferenceCancelled(data: any) {
    const conferenceName = data.payload.conferenceName;
    removeCall(conferenceName)
  }

  function onOutboundCallRejected(data: any) {
    const conferenceName = data.payload.conferenceName;
    removeCall(conferenceName)
    dispatch(setSnackbar({ message: 'Outbound call is rejected or timed out.', severity: SnackbarSeverity.WARNING }))
  }

  function onConferenceRequeued(data: any) {
    addUnansweredCall(data.payload.conferenceName, data.payload, true, true)
  }

  function onErrorReport(message: string, meta: any) {
    dispatch(setSnackbar({ message: meta.message, severity: SnackbarSeverity.ERROR }))
    thunkDispatch(stopAllProcessing())
    if (meta.endCall) {
      device?.disconnectAll()
      dispatch(clearAllCallTimeout())

      // If it's string, it's the conferenceName to remove
      if (typeof meta.endCall === 'string') {
        removeCall(meta.endCall)
      }
    }
  }

  function onUserUpdates(data: any) {
    const userUpdate = data.payload
    if (userUpdate?.id === user?.userId) {
      if (!userUpdate.active) {
        return dispatch(logout())
      }

      dispatch(addUserDetails({
        ...userUpdate,
        extension: userUpdate?.extension?.name,
        evoLevels: userUpdate?.extension?.evoLevels,
        spanishCalls: userUpdate?.spanishCalls?.toString(),
      }))
    }

    dispatch(addOrUpdateUser({ user: userUpdate, loggedInUserBlock: user?.details?.userBlock || user?.details?.teamBlock || '' }))
  }

  function onInactivateTab() {
    device?.disconnectAll()
    dispatch(setIsCallEnabled(false))
  }

  return socket
}

export default useEventsListener